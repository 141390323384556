<template>
  <div class="layout-config h-100 p-20">
    <h3 class="b-b-1 m-b-10">
      {{ $route.query.id ? "编辑" : "新增" }}研学全景图
    </h3>
    <Form
      ref="form"
      :model="formItem"
      :rules="rules"
      :label-width="100"
      label-colon
    >
      <Row :gutter="10">
        <Col span="12">
          <FormItem label="全景图名称" prop="name">
            <Input
              v-model="formItem.name"
              placeholder="请输入全景图名称"
              clearable
            />
          </FormItem>
          <FormItem label="全景图简介">
            <Button type="primary" icon="md-add" @click="addIntro"
              >添加简介</Button
            >
            <Button
              type="warning"
              icon="md-refresh"
              class="m-l-5"
              @click="resetIntro"
              >重置简介</Button
            >
            <template v-if="formItem.qjtBtn && formItem.qjtBtn.length">
              <Row
                type="flex"
                align="middle"
                v-for="(item, index) in formItem.qjtBtn"
                :key="index"
                class="m-t-5 m-b-5"
              >
                <Col span="4">
                  <Input
                    v-model="item.btnName"
                    placeholder="简介名称"
                    clearable
                  />
                </Col>
                <Col span="1" class="text-c">:</Col>
                <Col span="18">
                  <Input
                    v-model="item.btnText"
                    type="textarea"
                    placeholder="简介文本内容"
                    clearable
                  />
                </Col>
                <Col span="1" class="text-c">
                  <Button
                    type="error"
                    shape="circle"
                    icon="md-close"
                    size="small"
                    @click="delIntro(index)"
                  ></Button>
                </Col>
              </Row>
            </template>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="全景类型">
            <RadioGroup v-model="formItem.type">
              <Radio :label="0">单图</Radio>
              <Radio :label="1">多图</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="全景图图片" v-if="!formItem.type">
            <GeoFile
              v-model="formItem.url"
              text="选择图片"
              upload-txt="上传图片"
              :multiple="false"
              :folderId="2169"
              :param="{ folderId: 2169, userId: $store.state.user.userId }"
            />
          </FormItem>
          <FormItem label="全景图图片" v-if="formItem.type">
            <GeoFile
              v-model="formItem.urlList"
              text="选择图片"
              upload-txt="上传图片"
              :multiple="true"
              :folderId="2169"
              :param="{ folderId: 2169, userId: $store.state.user.userId }"
            />
            <p class="c-red m-t-5">*上传图片共6张，顺序需为：右、左、上、下、前、后</p>
          </FormItem>
        </Col>
      </Row>

      <FormItem>
        <Button type="success" @click="saveData">提交</Button>
        <Button type="default" class="m-l-5" @click="goBack">返回</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { util } from "@/libs";
import { getPanoramaById, addPanorama, updatePanorama } from "@/libs/api/panorama";
export default {
    data() {
        return {
            formItem: { type: 0 },
            rules: {
                name: { required: true, message: "请输入全景图名称" },
            },
            intro: {
                btnName: "",
                btnText: "",
            },
        };
    },
    computed: {
        ...mapState({
            userId: (state) => state.user.userId,
        }),
    },
    methods: {
        // 添加简介
        addIntro() {
            this.formItem.qjtBtn.push(util.deepcopy(this.intro));
        },
        // 重置简介
        resetIntro() {
            this.formItem.qjtBtn = [];
        },
        // 删除简介
        delIntro(index) {
            this.formItem.qjtBtn.splice(index, 1);
        },
        // 保存数据
        saveData() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    console.log(this.formItem);
                    if (!this.formItem.userId)
                        this.formItem.userId = this.userId;
                    if (this.formItem.id) {
                        // 编辑全景
                        updatePanorama(this.formItem).then((res) => {
                            this.successResult(res, "编辑成功!");
                        });
                    }
                    else {
                        // 添加全景
                        addPanorama(this.formItem).then((res) => {
                            this.successResult(res, "添加成功!");
                        });
                    }
                }
            });
        },
        // 新增修改成功回调
        successResult(res, descMsg) {
            if (res.code == "HA0200") {
                this.$Message.success(descMsg || res.msg);
                this.goBack();
            }
            else {
                this.$Message.error(res.msg);
            }
        },
        // 返回列表
        goBack() {
            this.$router.push("/config/dataManage/panorama");
        },
    },
    async created() {
        const { id } = this.$route.query;
        if (id) {
            getPanoramaById({ id }).then(({ code, data }) => {
                if (code == "HA0200") {
                    this.formItem = data;
                    this.formItem.type = data.type == true ? 1:  (data.type == false ? 0 : null)
                }
            });
        }
        else {
            this.formItem = {
                qjtBtn: [],
                type: 0
            };
        }
    },
};
</script>

<style lang="less" scoped>
.layout-config {
  overflow-y: scroll;
}
</style>
